import React, { useEffect, useState } from 'react'

import Footer from '../../components/Footer'
import TraditionalLogo from '../../assets/images/traditional-title-flag.png'

import HorizontalRectangle from '../../assets/svg/horizontal-rectangle.svg'
import TimeIcon from '../../assets/svg/time-icon.svg'
import CommunicationIcon from '../../assets/svg/communication-icon.svg'
import CloudIcon from '../../assets/svg/cloud-icon.svg'
import AcuracyIcon from '../../assets/svg/acuracy-icon.svg'

const About = () => {
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1536px)').matches
  )

  useEffect(() => {
    const aboutBackground = window.document.getElementById('aboutBackground')
    window
      .matchMedia('(min-width: 1536px)')
      .addEventListener('change', e => setMatches(e.matches))
    if (matches) {
      aboutBackground.style.background = `url(${HorizontalRectangle}) no-repeat left bottom`
    } else {
      aboutBackground.style.background = 'none'
    }
  }, [matches])

  return (
    <>
      <div id='aboutBackground' className='max-w-[1920px] mx-auto'>
        <div className='flex flex-col lg:flex-row max-w-[1500px] sm:mx-0 lg:mx-8 2xl:mx-auto lg:py-10  gap-10 '>
          <div className='flex p-[60px]  lg:min-w-[505px] lg:min-h-[460px] h-auto lg:self-start items-center justify-center  bg-[#F5F9FD] 2xl:flex-none  2xl:p-0 2xl:min-w-[695px] 2xl:min-h-[585px]'>
            <img
              className='w-fit h-auto'
              src={TraditionalLogo}
              alt='logo'
              width={497}
              height={269}
            />
          </div>
          <div className='flex flex-col self-center  mx-8 lg:mx-0 items-center py-8 lg:py-10 lg:items-start  2xl:mr-0 gap-10'>
            <h1 className=' text-3xl lg:text-5xl lg:text-left font-inter font-semibold '>
              About Us
            </h1>

            <p className=' text-base text-justify font-medium leading-9 font-inter'>
              Established in 1994, Traditional Title Services is a trusted
              leader in the title abstract industry. With over 1.5 million
              completed title searches, we bring over 25 years of experience and
              reliability to every client. Our dedicated team combines industry
              knowledge, cutting-edge technology, and exceptional customer
              service to deliver accurate and tailored title search reports.
              Trust us for all your title abstract needs and experience the
              expertise that sets us apart.
            </p>
          </div>
        </div>
      </div>
      <div className='lg:py-[100px]'>
        <div className='flex flex-col py-8 lg:py-10 gap-8 lg:gap-10 mx-8'>
          <div className='flex flex-col lg:py-10 gap-8 lg:gap-16 lg:flex-row max-w-[1200px] self-center'>
            <div className='flex flex-1 flex-col items-center lg:items-start self-stretch gap-10 '>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={TimeIcon}
                  alt='time-icon'
                  width={48}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  Timelines
                </h1>
              </div>
              <p className='font-inter font-medium text-justify text-base leading-9 '>
                With Georgia&#39;s largest staff of title professionals and an
                extensive statewide infrastructure, we have the ability to
                consistently complete searches in a timely manner. Many times,
                rush searches can be completed in just a few hours. Also, by
                leveraging the resources of our database of prior searches, we
                are not starting from scratch and this cuts down on the time it
                takes to get the searches back to you. Timeliness on our part
                means you can focus on growing your business of satisfied
                clients.
              </p>
            </div>
            <div className='hidden lg:inline-block h-[376px] min-h-[1em] w-0.5 self-end bg-[#D0D4D7] opacity-100 dark:opacity-50' />
            <div className='flex flex-1  flex-col items-center lg:items-start  self-stretch gap-10'>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={AcuracyIcon}
                  alt='acuracy-icon'
                  width={45}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  Accuracy
                </h1>
              </div>
              <p className='font-inter font-medium text-justify text-base leading-9 '>
                Our obsessive focus on accuracy helps you reduce liability. An
                experienced title checker reviews every search and document for
                execution and legal descriptions before you receive the final
                product. Also, our experience means that you will be notified of
                issues that other abstractors might miss. We will even let you
                know if a search needs to be reviewed by an underwriter from a
                title insurance company in an effort to make sure that you do
                not absorb any unnecessary liability in questionable situations.
              </p>
            </div>
          </div>
          <div className='flex flex-col lg:py-10 gap-8 lg:gap-16 lg:flex-row max-w-[1200px] self-center'>
            <div className='flex flex-1 flex-col items-center lg:items-start self-stretch gap-10 '>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={CommunicationIcon}
                  alt='plus-lens'
                  width={48}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  Communication
                </h1>
              </div>
              <p className='font-inter font-medium text-justify text-base leading-9 '>
                We will never surprise you. If your search uncovers unresolved
                issues, you will know. Search results include detailed
                information regarding what was found and what was not found, and
                our fulltime staff is always available to answer your questions.
                All email messages are stored and logged on our system, so you
                can review the entire dialogue for any file at any time.
              </p>
            </div>
            <div className='hidden lg:inline-block h-[376px] min-h-[1em] w-0.5 self-end bg-[#D0D4D7] opacity-100 dark:opacity-50' />
            <div className='flex flex-1  flex-col items-center lg:items-start  self-stretch gap-10'>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={CloudIcon}
                  alt='plus-lens'
                  width={68}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  Technology
                </h1>
              </div>
              <p className='font-inter font-medium text-justify text-base leading-9 '>
                Computerized order tracking, legal descriptions in digital
                format, and SoftPro and Landtech compatible software are just
                the beginning. You will also have access to real time order
                information through ClosingLink, a web based pre-closing system,
                that both you and your clients can use securely. You can see
                each order as it moves through our system, and once complete, a
                copy is indefinitely available for download. ClosingLink also
                provides a central communications hub for everyone involved in a
                closing: increasing efficiency and decreasing your liability.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default About
