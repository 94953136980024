import React from 'react'
import { useNavigate } from 'react-router-dom'
function Footer () {
  const navigate = useNavigate()
  return (
    <footer className=' shadow bg-[#245B8C] py-4 '>
      <div className='flex flex-col lg:flex-row max-w-[1200px] m-auto content-center items-center xl:justify-start lg:justify-center'>
        <span className=' block md:inline text-base tracking-wider font-medium leading-9 text-white'>
          Copyright ©2023 <span className=''>Traditional Title Services</span>.{' '}
          <span className='block md:inline text-center'>
            All Rights Reserved.
          </span>{' '}
          <span
            className='block md:inline text-center text-gray-300 text-base  tracking-wider  leading-9 font-medium cursor-pointer'
            onClick={() => navigate('/privacy')}>
            Privacy Policy
          </span>
        </span>
      </div>
    </footer>
  )
}

export default Footer
