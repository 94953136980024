import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '../../components/Button'
import Footer from '../../components/Footer'

import CapitolPark from '../../assets/images/building-front.jpg'
import HorizontalRectangle from '../../assets/svg/horizontal-rectangle.svg'

const Features = ({ url }) => {
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1536px)').matches
  )

  useEffect(() => {
    const featuresBackground =
      window.document.getElementById('featuresBackground')
    window
      .matchMedia('(min-width: 1536px)')
      .addEventListener('change', e => setMatches(e.matches))
    if (matches) {
      featuresBackground.style.background = `url(${HorizontalRectangle}) no-repeat left bottom`
    } else {
      featuresBackground.style.background = 'none'
    }
  }, [matches])

  return (
    <>
      <div id='featuresBackground' className='max-w-[1920px] mx-auto'>
        <div className='flex flex-col lg:flex-row max-w-[1500px] sm:mx-0 lg:mx-8 2xl:mx-auto lg:py-10  gap-10'>
          <div className='w-full lg:min-w-[505px] lg:min-h-[460px] 2xl:min-w-[695px] 2xl:min-h-[585px]'>
            <img
              className='w-[100%] h-[100%]'
              src={CapitolPark}
              alt='CapitolPark'
              width={695}
              height={585}
            />
          </div>
          <div className='flex flex-col self-center  mx-8 lg:mx-0 items-center py-10 lg:items-start  2xl:mr-0 gap-10'>
            <h1 className='text-4xl text-center lg:text-left lg:text-5xl font-inter font-semibold'>
              Exceptional Features
            </h1>
            <p className=' text-base text-justify font-medium leading-9 font-inter'>
              As a leading nationwide title search provider, we understand the
              importance of accuracy and efficiency in today&#39;s market.
              That&#39;s why we utilize cutting-edge technology and
              industry-leading expertise to deliver thorough and precise title
              search reports tailored to your unique needs.
            </p>
            <div className='flex justify-center '>
              <Button onClick={() => window.open(url)}>Order Now</Button>
            </div>
          </div>
        </div>
      </div>
      <div className='lg:py-[100px]'>
        <div className='flex flex-col py-8 lg:py-10 gap-10 mx-8'>
          <h1 className='text-4xl lg:text-5xl text-center font-inter font-semibold '>
            Title Search Inquiry
          </h1>
          <div className='flex flex-col lg:py-10 gap-10 lg:gap-16 lg:flex-row max-w-[1200px] self-center'>
            <div className='flex flex-1 flex-col items-center lg:items-start self-stretch gap-10 '>
              <p className='font-inter font-semibold text-2xl lg:text-4xl'>
                No Agent Liability
              </p>
              <p className='font-inter font-medium text-justify text-base leading-9 '>
                Traditional Title Services is an Approved Abstractor for every
                Title Insurance Company in Georgia. Additionally, we have
                special agreements in place with most of the Title Insurance
                companies and should there be any issue, the Agent is fully
                indemnified for any searching errors. Please contact your
                specific Title Insurance Company for complete details.
              </p>
            </div>
            <div className='hidden lg:inline-block h-[390px] min-h-[1em] w-0.5 self-end bg-[#D0D4D7] opacity-100 dark:opacity-50' />
            <div className='flex flex-1  flex-col items-center lg:items-start  self-stretch gap-10'>
              <p className='font-inter font-semibold text-2xl lg:text-4xl'>
                Searches Back in Hours
              </p>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                Every order placed is processed through our VIP TitleTM search
                engine and if a prior search is found, you will receive the new
                search back in as little as a few hours. If the new search is
                not delivered in 48 hours, then there is no fee.
              </p>
            </div>
          </div>
          <div className='flex flex-col lg:py-10  gap-10 lg:gap-16 lg:flex-row max-w-[1200px] self-center'>
            <div className='flex flex-1 flex-col items-center lg:items-start self-stretch gap-10 '>
              <p className='font-inter font-semibold text-2xl lg:text-4xl'>
                Patriot Act, Name Search
              </p>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                The Borrower and Sellers names are automatically run through the
                Office of Foreign Asset Control (OFAC) of the US Treasury
                Department to comply with the Patriot Act at the time the order
                is placed. If a name appears on the OFAC Sanctions List, the
                title request cannot be placed without approval from the Title
                Insurance Company.
              </p>
            </div>
            <div className='hidden lg:inline-block h-[390px] min-h-[1em] w-0.5 self-end bg-[#D0D4D7] opacity-100 dark:opacity-50' />
            <div className='flex flex-1  flex-col items-center lg:items-start  self-stretch gap-10'>
              <p className='font-inter font-semibold text-2xl lg:text-4xl'>
                Software Integration
              </p>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                Import and Export your orders from SoftPro®, LandTech or any
                other third-party software suite, and eliminate data entry
                errors and save time. Title information and legal descriptions
                are imported directly into your software.
              </p>
            </div>
          </div>
          <div className='flex flex-col lg:py-10 gap-10 lg:gap-16 lg:flex-row max-w-[1200px] self-center'>
            <div className='flex flex-1 flex-col items-center lg:items-start self-stretch gap-10 '>
              <p className='font-inter font-semibold text-2xl lg:text-4xl'>
                TitleScan AI
              </p>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                Using our proprietary technology, TitleScan AI scans your title
                documents for potentially problematic words and phrases,
                highlighting them within the document and providing you with a
                comprehensive TitleScan AI report for each title search.
                TitleScan AI reduces liability issues, offering enhanced risk
                assessment and uses cutting edge technology to help you in title
                document review process.
              </p>
            </div>
            <div className='hidden lg:inline-block h-[390px] min-h-[1em] w-0.5 self-end bg-[#D0D4D7] opacity-100 dark:opacity-50' />
            <div className='flex flex-1  flex-col items-center lg:items-start  self-stretch gap-10'>
              <p className='font-inter font-semibold text-2xl lg:text-4xl'>
                ClosingLink
              </p>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                Our online platform keeps track of all your communication,
                documents, order tracking, follow up issues and other requests.
                ClosingLink is cloud based and encrypted with 256-bit SSL
                security, providing secure and permanent storage of your
                documents and your communication.
              </p>
            </div>
          </div>
          <div className='flex flex-col lg:py-10 gap-10 lg:gap-16 lg:flex-row max-w-[1200px] self-center'>
            <div className='flex flex-1 flex-col items-center lg:items-start self-stretch gap-10 '>
              <p className='font-inter font-semibold text-2xl lg:text-4xl'>
                Review Process
              </p>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                All searches are reviewed at least three times before they are
                sent. The first review is done by the examiner at the
                courthouse, the next review is the processor and then finally
                the search is sent to a reviewer who looks at the finished
                product before it is sent to you. If the reviewer sees any
                issues it is escalated to a Senior Reviewer. This robust review
                process finds many issues that would otherwise be missed.
              </p>
            </div>
            <div className='hidden lg:inline-block h-[390px] min-h-[1em] w-0.5 self-end bg-[#D0D4D7] opacity-100 dark:opacity-50' />
            <div className='flex flex-1  flex-col items-center lg:items-start  self-stretch gap-10'>
              <p className='font-inter font-semibold text-2xl lg:text-4xl'>
                Nationwide Title Abstract Services
              </p>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                Offering comprehensive title abstract services across the US, we
                cover almost every county in every state. Our experienced team
                provides accurate and reliable information for property
                transactions. Trust us for nationwide coverage and exceptional
                service tailored to your needs.
              </p>
            </div>
          </div>
          <div className='flex justify-center'>
            <Button onClick={() => window.open(url)}>Order Now</Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

Features.propTypes = {
  url: PropTypes.string.isRequired
}

export default Features
