import React, { useEffect, useState } from 'react'

import Footer from '../../components/Footer'
import Lens from '../../assets/svg/lens.svg'
import TraditionalLogo from '../../assets/images/traditional-title-flag.png'
import HorizontalRectangle from '../../assets/svg/horizontal-rectangle.svg'
import JsonData from '../../assets/json/traditional-title-data.json'
import MobileTable from '../../components/MobileTable'
import DesktopTable from '../../components/DesktopTable'

const Pricing = () => {
  const [counties, setCounties] = useState('')
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1536px)').matches
  )
  const [mobileMatch, setmobileMatch] = useState(
    window.matchMedia('(max-width: 1024px)').matches
  )

  const renderTables = [
    {
      mobile: <MobileTable jsonData={JsonData} counties={counties} />,
      deskTop: <DesktopTable jsonData={JsonData} counties={counties} />
    }
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const pricingBackground =
      window.document.getElementById('pricingBackground')
    window
      .matchMedia('(min-width: 1536px)')
      .addEventListener('change', e => setMatches(e.matches))
    window
      .matchMedia('(max-width: 1024px)')
      .addEventListener('change', e => setmobileMatch(e.matches))
    if (matches) {
      pricingBackground.style.background = `url(${HorizontalRectangle}) no-repeat left bottom`
    } else {
      pricingBackground.style.background = 'none'
    }
  }, [matches, mobileMatch])

  return (
    <>
      <div id='pricingBackground' className='max-w-[1920px] mx-auto'>
        <div className='flex flex-col lg:flex-row max-w-[1500px] sm:mx-0 lg:mx-8 2xl:mx-auto lg:py-10  gap-10 '>
          <div className='flex p-[60px] lg:min-w-[505px] lg:min-h-[460px] h-auto lg:self-start items-center justify-center  bg-[#F5F9FD] 2xl:flex-none  2xl:p-0 2xl:min-w-[695px] 2xl:min-h-[585px]'>
            <img
              className='w-fit h-auto'
              src={TraditionalLogo}
              alt='logo'
              width={497}
              height={269}
            />
          </div>
          <div className='flex flex-col self-center  mx-8 lg:mx-0 items-center py-8 lg:py-10 lg:items-start  2xl:mr-0 gap-10'>
            <h1 className=' text-3xl lg:text-5xl lg:text-left font-inter font-semibold '>
              Transparent Pricing for Title Abstract Services
            </h1>

            <p className=' text-base text-justify font-medium leading-9 tracking-tight font-inter'>
              At Traditional Title Services, we believe in transparency when it
              comes to pricing. We offer competitive and straightforward pricing
              for our title abstract services, ensuring that you have a clear
              understanding of the costs involved. Our pricing structure is
              designed to provide value for our clients while maintaining the
              highest level of quality and accuracy in our reports. Contact us
              today to learn more about our pricing options and discover the
              exceptional value we bring to your title abstract needs.
            </p>
          </div>
        </div>
      </div>
      <div className='py-[100px] mx-8'>
        <div className='flex flex-col max-w-[1500px]  mx-auto'>
          <div className='flex  gap-6 max-[1024px]:flex-col felx-row justify-between items-center lg:items-start self-stretch w-full'>
            <div className='flex gap-2  pl-4 pr-20 py-2 border-[1px] rounded-lg w-fit'>
              <img src={Lens} alt='lens' />
              <input
                onChange={e => setCounties(e.target.value)}
                placeholder='Search Counties'
                className='placeholder:text-[#D0D4D7] font-inter text-sm lg:text-xl placeholder:font-semibold  font-medium lg:leading-9'
              />
            </div>
            <p className='font-inter text-[#294159] max-w-xs lg:max-w-none  text-sm lg:text-base lg:font-medium lg:leading-9  text-center'>
              * Additional copies are available but may require additional
              payment.
            </p>
          </div>
          <div className='flex pt-5 min-h-screen self-center 2xl:self-start'>
            {mobileMatch ? renderTables[0].mobile : renderTables[0].deskTop}
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Pricing
