import React from 'react'
import PropTypes from 'prop-types'
import { TableVirtuoso } from 'react-virtuoso'
const DesktopTable = ({ jsonData = [], counties = '' }) => {
  const renderTable = (
    <TableVirtuoso
      data={jsonData.filter(
        data => data.County.includes(counties) && data.Current !== ''
      )}
      style={{ height: '100%' }}
      itemContent={(index, data) => (
        <tbody>
          <tr
            key={index}
            tabIndex='0'
            className='bg-[#F5F9FD]  cursor-pointer hover:bg-[#D0D4D7] border-b-[1px] border-[#D0D4D7]'>
            <td className='font-inter text-[#294159] text-xs lg:text-base font-light lg:font-medium lg:leading-9 lg:tracking-wide w-[100px] text-center  py-4 px-4'>
              {data.State}
            </td>
            <td className='font-inter text-[#294159]  text-xs lg:text-base font-light lg:font-medium lg:leading-9 lg:tracking-wide  w-[200px] text-left'>
              {data.County}
            </td>
            <td className='font-inter text-[#294159]  text-xs lg:text-base font-light lg:font-medium lg:leading-9 lg:tracking-wide  w-[200px] text-center '>
              {data.Full}
            </td>
            <td className='font-inter text-[#294159]  text-xs lg:text-base font-light lg:font-medium lg:leading-9 lg:tracking-wide  w-[200px] text-center '>
              {data.Current}
            </td>
            <td className='font-inter text-[#294159]  text-xs lg:text-base font-light lg:font-medium lg:leading-9 lg:tracking-wide  w-[200px] text-center '>
              {data['2Owner']}
            </td>
            <td className='font-inter text-[#294159]  text-xs lg:text-base font-light lg:font-medium lg:leading-9 lg:tracking-wide  w-[200px] text-center '>
              {data.Developer}
            </td>
            <td className='font-inter text-[#294159]  text-xs lg:text-base font-light lg:font-medium lg:leading-9 lg:tracking-wide  w-[200px] text-center '>
              {data.Update}
            </td>
            <td className='font-inter text-[#294159]  text-xs lg:text-base font-light lg:font-medium lg:leading-9 lg:tracking-wide  w-[200px] text-center '>
              {data.Retrieval}
            </td>
          </tr>
        </tbody>
      )}
    />
  )
  return (
    <table className='block table-auto'>
      <thead>
        <tr className='bg-[#3F6285]'>
          <th className='text-white text-center  font-inter text-xs lg:text-xl font-semibold lg:leading-9 lg:tracking-wide w-[100px] px-4 py-4'>
            State
          </th>
          <th className='text-white text-left font-inter text-xs lg:text-xl font-semibold lg:leading-9 lg:tracking-wide w-[200px] px-4'>
            County
          </th>
          <th className='text-white text-center font-inter text-xs lg:text-xl font-semibold lg:leading-9 lg:tracking-wide w-[200px] px-4'>
            Full Search
          </th>
          <th className='text-white text-center  font-inter text-xs lg:text-xl font-semibold lg:leading-9 lg:tracking-wide  w-[200px] px-4'>
            Current Owner
          </th>
          <th className='text-white text-center  font-inter text-xs lg:text-xl font-semibold lg:leading-9 lg:tracking-wide  w-[200px] px-4'>
            2 Owner
          </th>
          <th className='text-white text-center  font-inter text-xs lg:text-xl font-semibold lg:leading-9 lg:tracking-wide w-[200px] px-4'>
            Developer 30y
          </th>
          <th className='text-white text-center  font-inter text-xs lg:text-xl font-semibold lg:leading-9 lg:tracking-wide  w-[200px] px-4'>
            Update
          </th>
          <th className='text-white text-center  font-inter text-xs lg:text-xl font-semibold lg:leading-9 lg:tracking-wide  w-[200px] px-4'>
            Doc Retriveal
          </th>
        </tr>
      </thead>
      {renderTable}
    </table>
  )
}

DesktopTable.propTypes = {
  jsonData: PropTypes.array,
  counties: PropTypes.string
}
export default DesktopTable
