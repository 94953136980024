import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Open from '../assets/svg/Open.svg'
import Close from '../assets/svg/Close.svg'
const Dropdown = ({ title = '', content = '', children }) => {
  //TODO: this component need to map the strigns and detect in case they are links, you will pass @linkText@ and replace that with the content of the children
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className='flex cursor-pointer transition-all flex-row py-2 px-4 justify-between self-stretch items-center bg-[#3F6285]'>
        <h1 className=' font-inter text-xl font-semibold leading-9 tracking-wide text-white'>
          {title}
        </h1>
        {isOpen ? (
          <img src={Close} alt='close-icon' width={15} height={10} />
        ) : (
          <img src={Open} alt='open-icon' width={15} height={10} />
        )}
      </div>

      <div
        className={
          isOpen
            ? 'h-auto flex-col bg-[#F5F9FD] opacity-100 p-4 transition-height ease-out  delay-100  duration-700'
            : 'transition-height ease-out delay-0  duration-700 overflow-hidden h-0 opacity-0 '
        }>
        <p className=' font-inter text-base font-medium leading-9'>
          {content}
          {children}
        </p>
      </div>

      <div className=' flex mt-5' />
    </>
  )
}

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  children: PropTypes.any
}
export default Dropdown
