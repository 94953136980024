import React, { useEffect, useState, useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import Footer from '../../components/Footer'
import TraditionalLogo from '../../assets/images/traditional-title-flag.png'

import HorizontalRectangle from '../../assets/svg/horizontal-rectangle.svg'
import TimeIcon from '../../assets/svg/time-icon.svg'
import CommunicationIcon from '../../assets/svg/communication-icon.svg'
import { Form } from '../../components/Form'

const ContactUs = () => {
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1536px)').matches
  )
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [captchaToken, setCaptchaToken] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('contatUs')

    setCaptchaToken(token)
  }, [executeRecaptcha])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const contactUsBackground = window.document.getElementById(
      'contactUsBackground'
    )
    window
      .matchMedia('(min-width: 1536px)')
      .addEventListener('change', e => setMatches(e.matches))
    if (matches) {
      contactUsBackground.style.background = `url(${HorizontalRectangle}) no-repeat left bottom`
    } else {
      contactUsBackground.style.background = 'none'
    }
  }, [matches])

  return (
    <>
      <div id='contactUsBackground' className='max-w-[1920px] mx-auto'>
        <div className='flex flex-col lg:flex-row max-w-[1500px] sm:mx-0 lg:mx-8 2xl:mx-auto lg:py-10  gap-10 '>
          <div className='flex p-[60px]  lg:min-w-[505px] lg:min-h-[460px] h-auto lg:self-start items-center justify-center  bg-[#F5F9FD] 2xl:flex-none  2xl:p-0 2xl:min-w-[695px] 2xl:min-h-[585px]'>
            <img
              className='w-fit h-auto'
              src={TraditionalLogo}
              alt='logo'
              width={497}
              height={269}
            />
          </div>
          <div className='flex flex-col self-center  mx-8 lg:mx-0 items-center py-10 lg:items-start  2xl:mr-0 gap-10'>
            <h1 className=' text-4xl text-center lg:text-5xl lg:text-left font-inter font-semibold '>
              Get in Touch with Traditional Title Services
            </h1>

            <p className=' text-base text-justify font-medium leading-9 font-inter'>
              We welcome the opportunity to assist you with your title abstract
              needs. Whether you have questions, require more information, or
              would like to request our services, our friendly and knowledgeable
              team is here to help. Feel free to reach out to us via phone,
              email, or the contact form below, and we will respond promptly. We
              look forward to providing you with exceptional customer service
              and ensuring a smooth and efficient experience with Traditional
              Title Services.
            </p>
          </div>
        </div>
      </div>
      <div className='lg:py-[100px]'>
        <div className='flex flex-col py-8 lg:py-10 gap-10 lg:mx-16'>
          <div className='flex flex-col py-10 gap-8 lg:gap-16 lg:flex-row max-w-[1200px] self-center w-full'>
            <div className='flex flex-1 flex-col items-center lg:items-start self-stretch gap-10'>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={CommunicationIcon}
                  alt='time-icon'
                  width={48}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  Contacts Us
                </h1>
              </div>
              {showSuccessMessage ? (
                <p className='font-inter font-medium text-justify text-base leading-9'>
                  Thank you for your interest in Traditional Title. We will
                  review your request and get back to you promptly.
                </p>
              ) : (
                <Form
                  captchaToken={captchaToken}
                  setShowSuccessMessage={setShowSuccessMessage}
                />
              )}
            </div>
            <div className='flex flex-1 flex-col items-center lg:items-start self-start gap-10'>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={TimeIcon}
                  alt='time-icon'
                  width={48}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  Office
                </h1>
              </div>
              <div className='flex flex-row max-[480px]:flex-col gap-10 w-[100%]'>
                <div className='flex flex-1'>
                  <p className='font-inter font-medium text-justify text-base leading-9'>
                    201 Allen Road <p>Suite 400 </p>
                    <p>Atlanta, GA 30328</p>
                  </p>
                </div>
                <div className='flex flex-1'>
                  <p className='font-inter font-medium text-justify text-base leading-9'>
                    8:30 AM to 5:00 PM <p>Monday - Friday</p>
                  </p>
                </div>
              </div>
              <p className='font-inter font-medium text-justify text-base leading-9 self-start'>
                Telephone: (404) 255-1252
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default ContactUs
