import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { useHookFormMask } from 'use-mask-input'

const schema = yup.object().shape({
  name: yup.string().required().required(),
  email: yup.string().email().required(),
  telephone: yup
    .string()
    .test(
      'len',
      'Phone Number must contain exactly 10 characters',
      val => val.replace(/[^\d]/g, '').length === 10
    ),
  description: yup.string().required().required()
})

export const Form = ({ captchaToken, setShowSuccessMessage }) => {
  const [showError, setShowError] = useState(false)
  const [isCallingApi, setIsCallingApi] = useState(false)
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    reset
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })
  const registerWithMask = useHookFormMask(register)
  const formSubmit = async () => {
    setShowError(false)
    const values = getValues()
    const url =
      process.env.URL_CONTACT_ME ||
      'https://test-api.vfa.io/ContactMe/api/contactMe/Send'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }

    try {
      setIsCallingApi(true)
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          description: values.description,
          email: values.email,
          name: values.name,
          telephone: values.telephone.replace(/[^\d]/g, ''),
          captcha: captchaToken
        })
      })

      if (response.status === 200) {
        setShowSuccessMessage(true)
        setIsCallingApi(false)
      }
    } catch (error) {
      setIsCallingApi(false)
      setShowError(true)
      console.error('ERROR MESSAGE FROM API CALL: ', error)
    }

    reset()
  }

  return (
    <>
      <form
        className='flex flex-col px-8 lg:px-0 gap-6 w-full'
        onSubmit={handleSubmit(formSubmit)}>
        {Object.keys(errors).length >= 1 && (
          <div className='block w-full h-[50px] border-[1px] rounded-md border-solid py-2 px-4 bg-[#E32C22]  placeholder-[#D0D4D7]'>
            <p className='font-inter font-medium text-base leading-9 text-white'>
              {errors?.telephone
                ? errors?.telephone?.message
                : 'Please fill out all the information below to submit.'}
            </p>
          </div>
        )}
        {showError && (
          <div className='block w-full h-auto border-[1px] rounded-md border-solid py-2 px-4 bg-[#E32C22]  placeholder-[#D0D4D7]'>
            <p className='font-inter font-medium text-base leading-9 text-white'>
              There was an error processing your request. We apologize for this
              issue and will resolve it promptly.. In the meantime please reach
              us at karenb@traditionaltitle.com
            </p>
          </div>
        )}
        <input
          {...register('name')}
          placeholder='Full Name'
          type='text'
          required
          className={`block w-full h-[50px] font-inter font-medium text-base leading-9 border-[1px] rounded-md border-[#D0D4D7] border-solid py-2 px-4 bg-white  placeholder-[#D0D4D7] `}
        />

        <input
          {...register('email')}
          placeholder='E-mail Address'
          type='email'
          required
          className={`block w-full h-[50px] font-inter font-medium text-base leading-9 border-[1px] rounded-md border-[#D0D4D7] border-solid py-2 px-4 bg-white  placeholder-[#D0D4D7] `}
        />

        <input
          {...registerWithMask('telephone', ['(999) 999-9999'], {
            required: true
          })}
          placeholder='Telephone Number'
          type='string'
          required
          className={`block w-full h-[50px] font-inter font-medium text-base leading-9 border-[1px] rounded-md border-[#D0D4D7] border-solid py-2 px-4 bg-white  placeholder-[#D0D4D7] `}
        />

        <textarea
          {...register('description')}
          placeholder='Please Describe Your Request'
          type='text'
          required
          className={`block w-full h-[140px] resize-none pb-[100px] font-inter font-medium text-base leading-9 border-[1px] rounded-md border-[#D0D4D7] border-solid py-2 px-4 bg-white  placeholder-[#D0D4D7] `}
        />

        <button
          disabled={isCallingApi}
          type='submit'
          className='bg-[#396B97] hover:bg-[#337AB7] self-center w-[145px] text-white font-bold px-6 py-3 rounded font-inter'>
          {isCallingApi ? 'Sending' : 'Submit'}
        </button>
      </form>
    </>
  )
}

Form.propTypes = {
  captchaToken: PropTypes.string,
  setShowSuccessMessage: PropTypes.func
}
