import React from 'react'
import PropTypes from 'prop-types'
import { TableVirtuoso } from 'react-virtuoso'
const MobileTable = ({ jsonData = [], counties = '' }) => {
  const renderTable = (
    <TableVirtuoso
      data={jsonData.filter(
        data => data.County.includes(counties) && data.Current !== ''
      )}
      style={{ height: '100%' }}
      itemContent={(index, data) => (
        <>
          <tr key={index} tabIndex='0' className=' bg-[#F5F9FD]'>
            <th className='bg-[#3F6285] border-2 text-white text-center  font-inter text-xs  font-semibold w-[200px] rounded-md'>
              State
            </th>
            <td className='font-inter text-[#294159] text-xs lg:text-base font-light  text-center border-2 rounded-md  w-[150px]'>
              {data.State}
            </td>
          </tr>
          <tr key={index} tabIndex='0' className=' bg-[#F5F9FD]'>
            <th className='bg-[#3F6285] border-2 text-white text-center  font-inter text-xs  font-semibold w-[200px] rounded-md'>
              County
            </th>
            <td className='font-inter text-[#294159] text-xs lg:text-base font-light  text-center border-2 rounded-md  w-[150px]'>
              {data.County}
            </td>
          </tr>
          <tr key={index} tabIndex='0' className=' bg-[#F5F9FD]'>
            <th className='bg-[#3F6285] border-2 text-white text-center  font-inter text-xs  font-semibold w-[200px] rounded-md'>
              Full Search
            </th>
            <td className='font-inter text-[#294159] text-xs lg:text-base font-light  text-center border-2 rounded-md  w-[150px]'>
              {data.Full}
            </td>
          </tr>
          <tr key={index} tabIndex='0' className=' bg-[#F5F9FD]'>
            <th className='bg-[#3F6285] border-2 text-white text-center  font-inter text-xs  font-semibold w-[200px] rounded-md'>
              Current Owner
            </th>
            <td className='font-inter text-[#294159] text-xs lg:text-base font-light  text-center border-2 rounded-md  w-[150px]'>
              {data.Current}
            </td>
          </tr>
          <tr key={index} tabIndex='0' className=' bg-[#F5F9FD]'>
            <th className='bg-[#3F6285] border-2 text-white text-center  font-inter text-xs  font-semibold w-[200px] rounded-md'>
              2 Owner
            </th>
            <td className='font-inter text-[#294159] text-xs lg:text-base font-light  text-center border-2 rounded-md  w-[150px]'>
              {data['2Owner']}
            </td>
          </tr>
          <tr key={index} tabIndex='0' className=' bg-[#F5F9FD]'>
            <th className='bg-[#3F6285] border-2 text-white text-center  font-inter text-xs  font-semibold w-[200px] rounded-md'>
              Developer 30y
            </th>
            <td className='font-inter text-[#294159] text-xs lg:text-base font-light  text-center border-2 rounded-md  w-[150px]'>
              {data.Developer}
            </td>
          </tr>
          <tr key={index} tabIndex='0' className=' bg-[#F5F9FD]'>
            <th className='bg-[#3F6285] border-2 text-white text-center  font-inter text-xs  font-semibold w-[200px] rounded-md'>
              Update
            </th>
            <td className='font-inter text-[#294159] text-xs lg:text-base font-light  text-center border-2 rounded-md  w-[150px]'>
              {data.Update}
            </td>
          </tr>
          <tr key={index} tabIndex='0' className=' bg-[#F5F9FD]'>
            <th className='bg-[#3F6285] border-2 text-white text-center  font-inter text-xs  font-semibold w-[200px] rounded-md'>
              Doc Retriveal
            </th>
            <td className='font-inter text-[#294159] text-xs lg:text-base font-light  text-center border-2 rounded-md  w-[150px]'>
              {data.Retrieval}
            </td>
          </tr>
          <div className=' my-4'></div>
        </>
      )}
    />
  )
  return (
    <table className='block  min-w-[300px]'>
      <thead>
        <th></th>
      </thead>
      {renderTable}
    </table>
  )
}

MobileTable.propTypes = {
  jsonData: PropTypes.array,
  counties: PropTypes.string
}
export default MobileTable
