import React from 'react'
import { createBrowserRouter, useNavigate, useLocation } from 'react-router-dom'
import Home from '../views/home/Home'
import Features from '../views/features/Features'
import Services from '../views/services/Services'
import Navigation from '../components/Navigation'
import About from '../views/about/About'
import ContactUs from '../views/contact-us/ContactUs'
import FAQs from '../views/faqs/FAQs'
import Pricing from '../views/pricing/Pricing'
import Privacy from '../views/privacy-policy/Privacy'

const michaelWatkinsUrl =
  'https://michaelwatkins.typeform.com/to/sSdsrH?typeform-source=www.traditionaltitle.com'

const appRouter = createBrowserRouter([
  {
    element: <Navigation useNavigate={useNavigate} useLocation={useLocation} />,
    children: [
      {
        path: '/',
        element: <Home url={michaelWatkinsUrl} useNavigate={useNavigate} />
      },
      {
        path: '/features',
        element: <Features url={michaelWatkinsUrl} />
      },
      {
        path: '/services',
        element: <Services url={michaelWatkinsUrl} />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/contact',
        element: <ContactUs />
      },
      {
        path: '/faqs',
        element: <FAQs />
      },
      {
        path: '/pricing',
        element: <Pricing />
      },
      {
        path: '/privacy',
        element: <Privacy />
      }
    ]
  }
])

export default appRouter
