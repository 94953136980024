import React, { useEffect } from 'react'

import Footer from '../../components/Footer'

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className='flex flex-col max-w-[1200px] mx-auto py-8 lg:py-10'>
        <div className='flex flex-col gap-10 mx-8'>
          <h1 className='text-3xl lg:text-5xl lg:text-left font-inter font-semibold '>
            Privacy Policy
          </h1>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            This Privacy Policy sets forth the practices of Traditional Title
            Services (&ldquo;we,&ldquo; &ldquo;ur,&ldquo; or &ldquo;us&ldquo;)
            regarding the collection, use, and protection of personal
            information provided by individuals (&ldquo;you&ldquo; or
            &ldquo;users&ldquo;) when using our website. We are dedicated to
            maintaining the privacy and security of your personal information
            and complying with applicable data protection laws. By accessing and
            using our website, you consent to the practices described in this
            Privacy Policy.
          </p>
          <h3 className='  text-xl  lg:text-[28px] font-inter font-semibold '>
            I. Information Collection
          </h3>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            We may collect various types of personal information from you,
            including but not limited to your name, contact details, email
            address, phone number, and any other information you voluntarily
            provide when interacting with our website. This information is
            collected through online forms, email communications, and other
            means of communication.
          </p>
          <h3 className='  text-xl  lg:text-[28px] font-inter font-semibold '>
            II. Use of Information
          </h3>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            1. The personal information we collect is primarily used to provide
            the requested services, respond to inquiries, and fulfill your
            requests. Additionally, we may use this information for internal
            record keeping, to improve our products and services, to customize
            the website according to your preferences, and to send periodic
            promotional emails or other updates that we think may be of interest
            to you. You have the right to opt-out of receiving such
            communications at any time.
          </p>
          <h3 className='  text-xl  lg:text-[28px] font-inter font-semibold '>
            III. Information Sharing
          </h3>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            We value your privacy and will never sell, rent, distribute, or
            lease your personal information to third parties without your
            consent, except as required by law or as necessary to provide our
            services. We may share your personal information with trusted
            third-party service providers who assist us in operating our website
            and delivering our services, subject to confidentiality obligations.
            These service providers are authorized to use your personal
            information solely for the purpose of providing the requested
            services.
          </p>
          <h3 className='  text-xl  lg:text-[28px] font-inter font-semibold '>
            IV. Data Security
          </h3>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            1. We are committed to ensuring the security of your personal
            information and have implemented reasonable physical, technical, and
            administrative measures to protect against unauthorized access,
            alteration, disclosure, or destruction of your personal data.
            However, please be aware that no method of transmission over the
            internet or electronic storage is 100% secure, and we cannot
            guarantee absolute security of your information.
          </p>
          <h3 className='  text-xl  lg:text-[28px] font-inter font-semibold '>
            V. Cookies and Tracking Technologies
          </h3>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            1. Our website may use cookies, web beacons, and similar tracking
            technologies to enhance your browsing experience, analyze trends,
            track user activities, and gather demographic information. These
            technologies help us understand how users interact with our website
            and improve its functionality. You have the option to disable
            cookies through your browser settings, but please note that this may
            limit certain features and functionality of our website.
          </p>
          <h3 className='  text-xl  lg:text-[28px] font-inter font-semibold '>
            VI. External Links
          </h3>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            Our website may contain links to third-party websites. These
            external websites have separate and independent privacy policies,
            and we have no responsibility or liability for the content and
            activities of these linked sites. We encourage you to review the
            privacy policies of those websites before providing any personal
            information.
          </p>
          <h3 className='  text-xl  lg:text-[28px] font-inter font-semibold '>
            VII. Your Rights
          </h3>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            You have the right to access, update, correct, and delete your
            personal information in accordance with applicable data protection
            laws. If you would like to exercise any of these rights or have any
            questions, concerns, or complaints regarding our handling of your
            personal information, please contact us using the information
            provided below.
          </p>
          <h3 className='  text-xl  lg:text-[28px] font-inter font-semibold '>
            VIII. Changes to the Privacy Policy
          </h3>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            We reserve the right to modify or update this Privacy Policy at any
            time. Any changes will be effective immediately upon posting the
            revised Privacy Policy on our website. We encourage you to review
            this Privacy Policy periodically for any updates or changes.
          </p>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            For any questions, concerns, or requests regarding this Privacy
            Policy or our data practices, please contact us at:
          </p>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            <p className='text-base text-justify font-bold leading-9 font-inter'>
              {' '}
              Traditional Title Services
            </p>
            <p>201 Allen Road</p> <p>Suite 400</p> <p>Atlanta, GA 30328</p>{' '}
          </p>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            <p className='text-base text-justify font-medium leading-9 font-inter'>
              <b>Telephone: </b> 404-255-1252
            </p>
            <p className='text-base text-justify font-medium leading-9 font-inter'>
              <b>E-mail: </b>info@traditionaltitle.com
            </p>
          </p>
          <p className='text-base text-justify font-medium leading-9 font-inter'>
            By using our website, you acknowledge that you have read,
            understood, and agreed to this Privacy Policy.
          </p>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Privacy
