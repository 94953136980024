import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer'
import Dropdown from '../../components/Dropdown'
import TraditionalLogo from '../../assets/images/traditional-title-flag.png'
import HorizontalRectangle from '../../assets/svg/horizontal-rectangle.svg'

const FAQs = () => {
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1536px)').matches
  )

  const linkContent = (
    <Link className='text-[#337AB7]' to='/pricing'>
      {' '}
      here
    </Link>
  )

  useEffect(() => {
    const aboutBackground = window.document.getElementById('aboutBackground')
    window
      .matchMedia('(min-width: 1536px)')
      .addEventListener('change', e => setMatches(e.matches))
    if (matches) {
      aboutBackground.style.background = `url(${HorizontalRectangle}) no-repeat left bottom`
    } else {
      aboutBackground.style.background = 'none'
    }
  }, [matches])

  return (
    <>
      <div id='aboutBackground' className='max-w-[1920px] mx-auto'>
        <div className='flex flex-col lg:flex-row max-w-[1500px] sm:mx-0 lg:mx-8 2xl:mx-auto lg:py-10  gap-10 '>
          <div className='flex p-[60px]  lg:min-w-[505px] lg:min-h-[460px] h-auto lg:self-start items-center justify-center  bg-[#F5F9FD] 2xl:flex-none  2xl:p-0 2xl:min-w-[695px] 2xl:min-h-[585px]'>
            <img
              className='w-fit h-auto'
              src={TraditionalLogo}
              alt='logo'
              width={497}
              height={269}
            />
          </div>
          <div className='flex flex-col self-center  mx-8 lg:mx-0 items-center py-10 lg:items-start  2xl:mr-0 gap-10'>
            <h1 className=' text-3xl lg:text-5xl lg:text-left font-inter font-semibold '>
              Frequently Asked Questions
            </h1>

            <p className=' text-base text-justify font-medium leading-9  font-inter'>
              We understand that navigating the world of title abstract services
              can be complex, which is why we&#39;ve compiled a list of
              frequently asked questions to provide you with the information you
              need. Whether you&#39;re a homeowner, real estate professional, or
              investor, this comprehensive FAQ section aims to address common
              inquiries and shed light on our services. Explore the topics below
              to find answers to your questions and gain a better understanding
              of our title abstract processes. If you can&#39;t find the
              information you&#39;re looking for, please don&#39;t hesitate to
              reach out to our knowledgeable team for further assistance.
            </p>
          </div>
        </div>
      </div>
      <div className='lg:py-[100px] mx-8'>
        <div className='flex flex-col pt-8 lg:py-10 max-w-[1200px] mx-auto'>
          <Dropdown
            title='What makes Traditional Title Services qualified to run searches?'
            content='Traditional Title Services is an Approved Abstractor for every Title Insurance Company in Georgia. Additionally, we have special agreements in place with most of the Title Insurance companies and should there be any issue, the Agent is fully indemnified for any searching errors. Please contact your specific Title Insurance Company for complete details.'
          />
          <Dropdown
            title='How long does a search take?'
            content='Most searches come back by the end of the next day. However, the time can vary depending on the complexity and difficulties contained within the search.'
          />
          <Dropdown
            title='How much are the searches?'
            content={`You can see a full list of the counties covered and the fees`}>
            {linkContent}
          </Dropdown>
          <Dropdown
            title='Can your searches be used by any attorney?'
            content='Traditional Title Services is an approved abstractor with all of the major title insurance providers in Georgia. Our searches can be used by any closing attorney. If a closing attorney refuses to use our search, please let us know, and we will find several other attorneys for you to choose from.'
          />
          <Dropdown
            title='Can the cost of a search change after it has been ordered?'
            content='Yes, if the search is unusually complex or time consuming, we will let you know that it cannot be completed inside the range of time allowed for the search type. If this occurs, we will let you know the additional cost. If you do not wish to proceed, we will refund your payment for the original search.'
          />
          <Dropdown
            title='Do you have Errors and Omissions Insurance?'
            content='Yes, we have a current Errors and Omissions Insurance policy. A copy is available upon request.'
          />
          <Dropdown
            title='Is anyone at Traditional Title Services an attorney?'
            content='While we have had attorneys work here in the past, currently, there are no attorneys employed by Traditional Title Services.'
          />
          <Dropdown
            title='What if I find something incorrect or missing in my search?'
            content='Searching through millions of records is a difficult task, and sometimes things can get overlooked during the screening process. If you think you have found an error, just let us know and we will go back and look into the issue for you. Sometimes there is an error at the courthouse and documents are not recorded correctly, sometimes it is simply human error, and we will correct it for you.'
          />
        </div>
      </div>

      <Footer />
    </>
  )
}

export default FAQs
