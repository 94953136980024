import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import flag from '../assets/images/flag.png'
import text from '../assets/images/TTS_logo_text.png'
import { Outlet } from 'react-router'

const Navigation = ({ useNavigate, useLocation }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const menu = useRef(null)
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1536px)').matches
  )

  const [openTab, setOpenTab] = React.useState(1)
  const [showHambuergerMenu, setShowHambuergerMenu] = React.useState(false)

  useEffect(() => {
    window
      .matchMedia('(min-width: 1536px)')
      .addEventListener('change', e => setMatches(e.matches))
    switch (location.pathname) {
      case '/':
        setOpenTab(1)
        break
      case '/features':
        setOpenTab(2)
        break
      case '/services':
        setOpenTab(3)
        break
      case '/about':
        setOpenTab(4)
        break
      case '/faqs':
        setOpenTab(5)
        break
      case '/contact':
        setOpenTab(0)
        break
      default:
        setOpenTab(0)
    }
  }, [matches, location])

  return (
    <>
      <header className='lg:py-8'>
        <nav className='max-w-[1500px] mx-auto '>
          <div className=' flex justify-between content-center mx-8 2xl:mx-0 '>
            <div className='hidden lg:flex items-center space-x-6 xl:space-x-16'>
              <div
                onClick={e => {
                  e.preventDefault()
                  setOpenTab(1)
                  navigate('/')
                }}
                className='flex items-center gap-4 flex-shrink-0 cursor-pointer'>
                <img src={flag} alt='flag' width={73} height={45} />
                <img src={text} alt='text' width={142} height={24} />
              </div>
              <p
                onClick={e => {
                  e.preventDefault()
                  setOpenTab(1)
                  navigate('/')
                }}
                className={
                  'cursor-pointer font-inter font-medium py-4 px-2 text-lg active:text-[#337AB7] ' +
                  (openTab === 1 ? 'text-[#337AB7]' : 'text-[#3F6285]')
                }>
                Home
              </p>
              <p
                onClick={e => {
                  e.preventDefault()
                  setOpenTab(2)
                  navigate('/features')
                }}
                className={
                  'cursor-pointer font-inter font-medium py-4 px-2 text-lg active:text-[#337AB7] ' +
                  (openTab === 2 ? 'text-[#337AB7]' : 'text-[#3F6285]')
                }>
                Features
              </p>
              <p
                onClick={e => {
                  e.preventDefault()
                  setOpenTab(3)
                  navigate('/services')
                }}
                className={
                  'cursor-pointer font-inter font-medium py-4 px-2 text-lg active:text-[#337AB7] ' +
                  (openTab === 3 ? 'text-[#337AB7]' : 'text-[#3F6285]')
                }>
                Services
              </p>
              <p
                onClick={e => {
                  e.preventDefault()
                  setOpenTab(4)
                  navigate('/about')
                }}
                className={
                  'cursor-pointer font-inter font-medium py-4 px-2 text-lg active:text-[#337AB7] ' +
                  (openTab === 4 ? 'text-[#337AB7]' : 'text-[#3F6285]')
                }>
                About
              </p>
              <p
                onClick={e => {
                  e.preventDefault()
                  setOpenTab(5)
                  navigate('/faqs')
                }}
                className={
                  'cursor-pointer font-inter font-medium py-4 px-2 text-lg active:text-[#337AB7] ' +
                  (openTab === 5 ? 'text-[#337AB7]' : 'text-[#3F6285]')
                }>
                FAQs
              </p>
            </div>
            <div className=' hidden lg:flex items-center flex-shrink-0'>
              <button
                onClick={e => {
                  e.preventDefault()
                  setOpenTab(0)
                  navigate('/contact')
                }}
                className='font-inter text-lg text-center text-white border-1 py-3 px-6 bg-[#396B97] rounded-md'>
                Contact Us
              </button>
            </div>
          </div>
          <div
            ref={menu}
            className='lg:hidden flex relative items-center justify-between px-6 py-4'>
            <div
              onClick={e => {
                e.preventDefault()
                setOpenTab(1)
                navigate('/')
              }}
              className='flex items-center gap-4 cursor-pointer '>
              <img src={flag} alt='flag' width={73} height={45} />
              <img src={text} alt='text' width={142} height={24} />
            </div>
            <div>
              <button
                className='outline-none mobile-menu-button'
                onClick={() => setShowHambuergerMenu(prevState => !prevState)}>
                <svg
                  width='32'
                  height='29'
                  viewBox='0 0 32 29'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M1.14286 5.64286H30.8571C31.4884 5.64286 32 5.13121 32 4.5V1.64286C32 1.01164 31.4884 0.5 30.8571 0.5H1.14286C0.511643 0.5 0 1.01164 0 1.64286V4.5C0 5.13121 0.511643 5.64286 1.14286 5.64286ZM1.14286 17.0714H30.8571C31.4884 17.0714 32 16.5598 32 15.9286V13.0714C32 12.4402 31.4884 11.9286 30.8571 11.9286H1.14286C0.511643 11.9286 0 12.4402 0 13.0714V15.9286C0 16.5598 0.511643 17.0714 1.14286 17.0714ZM1.14286 28.5H30.8571C31.4884 28.5 32 27.9884 32 27.3571V24.5C32 23.8688 31.4884 23.3571 30.8571 23.3571H1.14286C0.511643 23.3571 0 23.8688 0 24.5V27.3571C0 27.9884 0.511643 28.5 1.14286 28.5Z'
                    fill='#396B97'
                  />
                </svg>
              </button>
            </div>
          </div>

          <div
            className={
              showHambuergerMenu
                ? 'flex lg:hidden  h-[calc(100vh-77px)] absolute w-full items-center flex-col pt-8 px-8 border-[#3F6285]  bg-white border-x-0 border-[3px]  gap-6 z-10'
                : 'hidden'
            }>
            <p
              onClick={e => {
                e.preventDefault()
                setOpenTab(1)
                navigate('/')
                setShowHambuergerMenu(false)
              }}
              className={
                'cursor-pointer font-inter font-medium  text-lg active:text-[#337AB7] ' +
                (openTab === 1 ? 'text-[#337AB7]' : 'text-[#3F6285]')
              }>
              Home
            </p>

            <p
              onClick={e => {
                e.preventDefault()
                setOpenTab(2)
                navigate('/features')
                setShowHambuergerMenu(false)
              }}
              className={
                'cursor-pointer font-inter font-medium  text-lg active:text-[#337AB7] ' +
                (openTab === 2 ? 'text-[#337AB7]' : 'text-[#3F6285]')
              }>
              Features
            </p>

            <p
              onClick={e => {
                e.preventDefault()
                setOpenTab(3)
                navigate('/services')
                setShowHambuergerMenu(false)
              }}
              className={
                'cursor-pointer font-inter font-medium  text-lg active:text-[#337AB7] ' +
                (openTab === 3 ? 'text-[#337AB7]' : 'text-[#3F6285]')
              }>
              Services
            </p>

            <p
              onClick={e => {
                e.preventDefault()
                setOpenTab(4)
                navigate('/about')
                setShowHambuergerMenu(false)
              }}
              className={
                'cursor-pointer font-inter font-medium  text-lg active:text-[#337AB7] ' +
                (openTab === 4 ? 'text-[#337AB7]' : 'text-[#3F6285]')
              }>
              About
            </p>

            <p
              onClick={e => {
                e.preventDefault()
                setOpenTab(5)
                navigate('/faqs')
                setShowHambuergerMenu(false)
              }}
              className={
                'cursor-pointer font-inter font-medium  text-lg active:text-[#337AB7] ' +
                (openTab === 5 ? 'text-[#337AB7]' : 'text-[#3F6285]')
              }>
              FAQs
            </p>

            <div>
              <button
                onClick={() => {
                  setOpenTab(0)
                  navigate('/contact')
                  setShowHambuergerMenu(false)
                }}
                className='font-inter text-lg text-center text-white border-1 py-3 px-6 bg-[#396B97] rounded-md'>
                Contact Us
              </button>
            </div>
          </div>
        </nav>
      </header>

      <Outlet />
    </>
  )
}

Navigation.propTypes = {
  useNavigate: PropTypes.func,
  useLocation: PropTypes.func
}

export default Navigation
