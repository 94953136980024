import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../../components/Button'
import Footer from '../../components/Footer'
import TraditionalLogo from '../../assets/images/traditional-title-flag.png'

import HorizontalRectangle from '../../assets/svg/horizontal-rectangle.svg'
import PlusLensIcon from '../../assets/svg/plus-lens-icon.svg'
import MinusLensIcon from '../../assets/svg/minus-lens-icon.svg'
import ReportIcon from '../../assets/svg/report-icon.svg'
import UpdateIcon from '../../assets/svg/update-icon.svg'
import StarIcon from '../../assets/svg/star-icon.svg'
import StateWideIcon from '../../assets/svg/statewide-icon.svg'

const Services = ({ url }) => {
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 1536px)').matches
  )

  useEffect(() => {
    const servicesBackground =
      window.document.getElementById('servicesBackground')
    window
      .matchMedia('(min-width: 1536px)')
      .addEventListener('change', e => setMatches(e.matches))
    if (matches) {
      servicesBackground.style.background = `url(${HorizontalRectangle}) no-repeat left bottom`
    } else {
      servicesBackground.style.background = 'none'
    }
  }, [matches])

  return (
    <>
      <div id='servicesBackground' className='max-w-[1920px] mx-auto'>
        <div className='flex flex-col lg:flex-row max-w-[1500px] sm:mx-0 lg:mx-8 2xl:mx-auto lg:py-10  gap-8 lg:gap-10 '>
          <div className='flex p-[60px] lg:min-w-[505px] lg:min-h-[460px] h-auto lg:self-start items-center justify-center  bg-[#F5F9FD] 2xl:flex-none  2xl:p-0 2xl:min-w-[695px] 2xl:min-h-[585px]'>
            <img
              className='w-fit h-auto'
              src={TraditionalLogo}
              alt='logo'
              width={497}
              height={269}
            />
          </div>
          <div className='flex flex-col self-center  mx-8 lg:mx-0 items-center py-8 lg:py-10 lg:items-start  2xl:mr-0 gap-10'>
            <h1 className=' text-4xl text-center lg:text-5xl lg:text-left font-inter font-semibold '>
              Reliable Title Abstract Services
            </h1>

            <p className=' text-base text-justify font-medium leading-9 font-inter'>
              At Traditional Title Services, we pride ourselves on providing
              reliable and comprehensive title abstract services. With our years
              of experience and expertise, we offer accurate and thorough title
              search reports tailored to your specific needs. Our team utilizes
              industry-leading technology and attention to detail to ensure the
              highest level of accuracy in our findings. Whether you&#39;re a
              homeowner, lender, attorney, or real estate professional, you can
              trust us to deliver dependable and trustworthy results for your
              title abstract needs.
            </p>
          </div>
        </div>
      </div>
      <div className='lg:py-[100px]'>
        <div className='flex flex-col py-8 lg:py-10 gap-10 mx-8'>
          <div className='flex flex-col lg:py-10 gap-10 lg:gap-16 lg:flex-row max-w-[1200px] self-center'>
            <div className='flex flex-1 flex-col items-center lg:items-start self-stretch gap-10 '>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={PlusLensIcon}
                  alt='plus-lens'
                  width={48}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  Full Deed Search
                </h1>
              </div>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                A Full Search is the most common type of search and is used for
                the sale of a property. The scope of the search is 50 years or
                back to the root deed where the property was acquired by the
                developer. Included in the report is a chain of title of at
                least 24 months. Copies of the current deed and all open loans
                and liens.
              </p>
            </div>
            <div className='hidden lg:inline-block h-[268px] min-h-[1em] w-0.5 self-end bg-[#D0D4D7] opacity-100 dark:opacity-50' />
            <div className='flex flex-1  flex-col items-center lg:items-start  self-stretch gap-10'>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={MinusLensIcon}
                  alt='plus-lens'
                  width={48}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  Limited Search
                </h1>
              </div>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                A Limited Search provides the information on the current owner
                of the property. The time period of the search will vary
                depending on when the property was acquired. Included in the
                report is a chain of title of at least 24 months. Copies of the
                current deed and all open loans and liens.
              </p>
            </div>
          </div>
          <div className='flex flex-col lg:py-10 gap-10 lg:gap-16 lg:flex-row max-w-[1200px] self-center'>
            <div className='flex flex-1 flex-col items-center lg:items-start self-stretch gap-10 '>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={ReportIcon}
                  alt='plus-lens'
                  width={48}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  Deed Report
                </h1>
              </div>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                A Deed Report provides the deed where the current owner acquired
                the property. The time period of the search will vary depending
                on when the property was acquired. A copy of the current deed is
                included.
              </p>
            </div>
            <div className='hidden lg:inline-block h-[268px] min-h-[1em] w-0.5 self-end bg-[#D0D4D7] opacity-100 dark:opacity-50' />
            <div className='flex flex-1  flex-col items-center lg:items-start  self-stretch gap-10'>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={UpdateIcon}
                  alt='plus-lens'
                  width={48}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  Updates
                </h1>
              </div>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                An Update checks the records to see if anything has changed
                since a prior title search. If Traditional Title has completed a
                prior search, just let us know in the comments section. If we
                have not completed a prior search, please upload your document
                in PDF format in the Document Upload section on the Order page.
              </p>
            </div>
          </div>
          <div className='flex flex-col lg:py-10 gap-10 lg:gap-16 lg:flex-row max-w-[1200px] self-center'>
            <div className='flex flex-1 flex-col items-center lg:items-start self-stretch gap-10 '>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={StarIcon}
                  alt='plus-lens'
                  width={48}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  VIP Service
                </h1>
              </div>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                The VIP service provides an 8 &quot;courthouse&quot; hour
                turnaround. If we do not get the search back within this time
                period, then we will refund the entire amount paid. This service
                has an additional cost of $50.
              </p>
            </div>
            <div className='hidden lg:inline-block h-[268px] min-h-[1em] w-0.5 self-end bg-[#D0D4D7] opacity-100 dark:opacity-50' />
            <div className='flex flex-1  flex-col items-center lg:items-start  self-stretch gap-10'>
              <div className='flex flex-row items-center gap-4'>
                <img
                  className=''
                  src={StateWideIcon}
                  alt='plus-lens'
                  width={48}
                  height={48}
                />
                <h1 className='font-inter font-semibold text-2xl lg:text-4xl'>
                  Nationwide Coverage
                </h1>
              </div>
              <p className='font-inter font-medium text-justify text-base leading-9 '>
                Offering comprehensive title abstract services across the US, we
                cover almost every county in every state. Our experienced team
                provides accurate and reliable information for property
                transactions. Trust us for nationwide coverage and exceptional
                service tailored to your needs.
              </p>
            </div>
          </div>

          <div className='flex justify-center'>
            <Button onClick={() => window.open(url)}>Order Now</Button>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

Services.propTypes = {
  url: PropTypes.string.isRequired
}

export default Services
