import React from 'react'
import PropTypes from 'prop-types'
const Button = ({ type = 'button', onClick, children }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className='bg-[#337AB7] hover:bg-[#337AB7]  text-white font-bold py-3.5 px-20 rounded font-inter'>
      {children}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any
}
export default Button
