import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../components/Button'
import Separator from '../../components/Separator'
import Footer from '../../components/Footer'

import Capitol from '../../assets/images/building.jpg'
import HomeCheckIcon from '../../assets/svg/home-check-icon.svg'
import HomeWhiteIcon from '../../assets/svg/home-white-icon.svg'
import HomeSearchIcon from '../../assets/svg/home-seartch-icon.svg'
import ScrewHome from '../../assets/svg/screw-home-icon.svg'
import DocumentHomeIcon from '../../assets/svg/document-home-icon.svg'
import PropertyOwnerIcon from '../../assets/svg/property-owner-icon.svg'
import ShakeHandsIcon from '../../assets/svg/shake-hands-icon.svg'
import OfacPicture from '../../assets/images/ofac-picture.png'

const Home = ({ useNavigate, url }) => {
  const navigate = useNavigate()

  return (
    <>
      <div className=' bg-[#F5F9FD]'>
        <div className='flex flex-col lg:flex-row pb-8 lg:py-10 lg:px-8 2xl:px-0 gap-10 max-w-[1500px] mx-auto'>
          <div className='w-full lg:min-w-[505px]  lg:max-h-[460px] 2xl:min-w-[695px] 2xl:min-h-[585px]'>
            <img
              className='w-[100%] h-[100%]'
              src={Capitol}
              alt='Capitol'
              width={473}
              height={425}
            />
          </div>
          <div className='flex flex-col items-center px-8 lg:px-0 lg:items-start justify-center gap-10 '>
            <h1 className='text-4xl text-center lg:text-5xl lg:text-left font-inter font-semibold '>
              Nationwide Title Search Services
            </h1>
            <h2 className='lg:text-left text-2xl text-center lg:text-4xl font-semibold font-inter '>
              Fast, Accurate & Reliable
            </h2>
            <p className=' lg:text-left text-base text-justify font-medium leading-9 font-inter'>
              Discover our top-rated, nationwide title search services,
              providing fast and accurate searches for residential and
              commercial properties. With a rich history dating back to 1994,
              Traditional Title Services has successfully conducted over 1.5
              million title searches. Our dedication to pushing the boundaries
              of accuracy, timeliness, communication and technology helped make
              Traditional Title Services a gold standard for title searches
              nationwide. Trust our expert team for all your title abstract
              needs.
            </p>
            <div className='flex justify-center'>
              <Button onClick={() => window.open(url)}>Order Now</Button>
            </div>
          </div>
        </div>
      </div>
      <Separator />
      <div className='flex flex-col gap-0 lg:gap-10 py-10 px-8 2xl:px-0 max-w-[1500px] mx-auto'>
        <h1 className='text-2xl lg:text-5xl text-center font-inter font-semibold'>
          Our Comprehensive Title Search Services Include:
        </h1>
        <div className='flex flex-col lg:flex-row gap-8 xl:gap-16 py-8'>
          <div className='flex flex-1 flex-col items-start self-stretch py-12 px-8 bg-[#F5F9FD] gap-8'>
            <img
              className=''
              src={HomeCheckIcon}
              alt='home-check'
              width={80}
              height={80}
            />
            <p className='font-inter font-semibold text-xl'>Title Abstracts:</p>
            <p className='font-inter font-normal text-sm leading-7'>
              Our expert team meticulously reviews property records to provide a
              detailed abstract, highlighting any potential issues, such as
              liens, encumbrances, or ownership disputes.
            </p>
          </div>
          <div className='flex flex-1 flex-col items-start self-stretch py-12 px-8 bg-[#245B8C] gap-8'>
            <img
              className=''
              src={HomeWhiteIcon}
              alt='home-white'
              width={80}
              height={80}
            />
            <p className='font-inter font-semibold text-xl text-white'>
              Chain of Title:
            </p>
            <p className='font-inter font-normal text-sm text-white leading-7'>
              We trace the property&#39;s ownership history to ensure a clear
              and undisputed chain of title, giving you the confidence you need
              to move forward with your transaction.
            </p>
          </div>
          <div className='flex flex-1 flex-col items-start self-stretch py-12 px-8 bg-[#F5F9FD] gap-8'>
            <img
              className=''
              src={HomeSearchIcon}
              alt='home-search'
              width={80}
              height={80}
            />
            <p className='font-inter font-semibold text-xl'>Lien Searches:</p>
            <p className='font-inter font-normal text-sm leading-7'>
              Our nationwide title search services include thorough lien
              searches to uncover any outstanding debts or obligations tied to
              the property, protecting you from unforeseen financial
              liabilities.
            </p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row gap-8 xl:gap-16 py-0 lg:py-8 '>
          <div className='flex flex-1 flex-col items-start self-stretch py-12 px-8 bg-[#F5F9FD] gap-8'>
            <img
              className=''
              src={ScrewHome}
              alt='screw-home'
              width={80}
              height={80}
            />
            <p className='font-inter font-semibold text-xl'>
              Easements & Restrictions:
            </p>
            <p className='font-inter font-normal text-sm leading-7'>
              We identify any easements, restrictions, or other encumbrances
              that may impact your property&#39;s value and usage, providing you
              with crucial information to make informed decisions.
            </p>
          </div>
          <div className='flex flex-1 flex-col items-start self-stretch py-12 px-8 bg-[#F5F9FD] gap-8'>
            <img
              className=''
              src={DocumentHomeIcon}
              alt='document-icon'
              width={60}
              height={80}
            />
            <p className='font-inter font-semibold text-xl'>
              Document Retrieval:
            </p>
            <p className='font-inter font-normal text-sm leading-7'>
              Our team swiftly retrieves and reviews all necessary documents
              related to your property, ensuring you have a complete
              understanding of its history and current status.
            </p>
          </div>
          <div className='flex flex-1 flex-col items-start self-stretch py-12 px-8 bg-[#F5F9FD] gap-8'>
            <img className='' src={OfacPicture} alt='home-check' />
            <p className='font-inter font-semibold text-xl'>
              Patriot Act, Name Search
            </p>
            <p className='font-inter font-normal text-sm leading-7'>
              The Borrower and Sellers names are automatically run through the
              Office of Foreign Asset Control (OFAC) of the US Treasury
              Department to comply with the Patriot Act at the time the order is
              placed.
            </p>
          </div>
        </div>
      </div>

      <div className='py-10  lg:py-[100px] bg-[#F5F9FD]'>
        <div className='flex flex-col py-10 gap-10 mx-8'>
          <h1 className='text-4xl lg:text-5xl text-center font-inter font-semibold '>
            Title Search Inquiry
          </h1>
          <div className='flex flex-col py-10 gap-16 lg:flex-row max-w-[1200px] self-center'>
            <div className='flex flex-1 flex-col items-center lg:items-start self-stretch gap-10 '>
              <img
                className=''
                src={PropertyOwnerIcon}
                alt='property-owner-icon'
                width={79}
                height={79}
              />
              <p className='font-inter font-semibold text-2xl lg:text-4xl'>
                Property Owners
              </p>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                You can research your own property to make sure your title has
                been accurately recorded, and that all satisfied old loans have
                been cancelled, and also to make sure that you have no liens
                against you or your property that should not be there. Also, if
                you are thinking about selling your property, you can have a
                title search run up front, and know if there are any issues that
                may affect the sale of your property. You can use the title
                search when the sale of your property occurs, and reduce the
                cost of the closing.
              </p>
            </div>
            <div className='hidden lg:inline-block h-[390px] min-h-[1em] w-0.5 self-end bg-[#D0D4D7] opacity-100 dark:opacity-50' />
            <div className='flex flex-1  flex-col items-center lg:items-start  self-stretch gap-10'>
              <img
                className=''
                src={ShakeHandsIcon}
                alt='shake-hands-icon'
                width={63}
                height={80}
              />
              <p className='font-inter font-semibold text-2xl lg:text-4xl'>
                Potential Buyers
              </p>
              <p className='font-inter font-medium text-justify text-base leading-9'>
                You can also research a property you are thinking about
                purchasing. You will know up front what the current owner paid
                for the property, what loans are on the property and if there
                are any liens or unpaid taxes. Having a title search up front
                can give buyers leverage in the negotiating process.
                Additionally, if the property is purchased, you can use your
                title search for the closing of your purchase, and reduce the
                cost of the closing.
              </p>
            </div>
          </div>
          <div className='flex justify-center'>
            <Button onClick={() => window.open(url)}>Order Now</Button>
          </div>
        </div>
      </div>
      <div className='flex flex-col lg:flex-row lg:items-start max-w-[1200px] mx-auto pt-20 gap-20 lg:px-8 2xl:px-0 '>
        <div className='flex  pb-[100px] mx-8 lg:mx-0 flex-col lg:w-[560px] justify-center gap-10 lg:items-start self-stretct'>
          <h1 className=' text-3xl lg:text-5xl text-center lg:text-left  font-inter font-semibold '>
            Get In Touch With Traditional Title Services
          </h1>
          <p className='text-base text-center lg:text-justify  font-medium leading-9  font-inter'>
            By choosing our nationwide title search services, you&#39;ll benefit
            from the extensive knowledge and expertise of our dedicated team,
            who are committed to providing exceptional service and accurate
            results. Don&#39;t leave your real estate investment to chance –
            trust our nationwide title abstract company for fast, reliable, and
            comprehensive title search solutions.
          </p>
          <div className='flex justify-center'>
            <Button onClick={() => navigate('/contact')}>Contact Us</Button>
          </div>
        </div>

        <div className='flex flex-col box-content lg:w-[432px] lg:items-start items-center lg:rounded-t-md lg:self-stretch px-16 py-12 bg-[#1B4C77] gap-10'>
          <p className='font-inter font-medium text-xl text-[#6691B6]'>
            Office Hours
          </p>
          <p className='font-inter font-semibold text-base text-white'>
            8:30 AM to 5:00 PM <p>Monday To Friday</p>
          </p>
          <p className='font-inter font-medium text-xl text-[#6691B6]'>
            Telephone
          </p>
          <p className='font-inter font-semibold text-base text-white'>
            (404) 255-1252
          </p>

          <p className='font-inter font-medium text-xl text-[#6691B6]'>
            Address
          </p>
          <p className=' font-inter font-semibold text-base text-white'>
            201 Allen Road <p>Suite 400</p> <p>Atlanta, GA 30328</p>
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

Home.propTypes = {
  useNavigate: PropTypes.func,
  url: PropTypes.string.isRequired
}

export default Home
